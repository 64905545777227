export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, height=device-height,  initial-scale=1.0, user-scalable=no,user-scalable=0"},{"hid":"description","name":"description","content":""},{"name":"format-detection","content":"telephone=no"},{"http-equiv":"X-Content-Type-Options","content":"nosniff"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"href":"https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css","rel":"stylesheet"},{"href":"https://fonts.googleapis.com/css2?family=Raleway:wght@100;400;500;600;700&display=swap","rel":"stylesheet"}],"style":[],"script":[{"src":"https://kit.fontawesome.com/b6a72d85f5.js"},{"src":"https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"},{"src":"https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"},{"src":"https://cdn.plaid.com/link/v2/stable/link-initialize.js"}],"noscript":[],"title":"ClaimsSnap","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false