import validate from "/home/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "approved-only": () => import("/home/app/snaprefund-frontend/middleware/approved-only.ts"),
  authenticated: () => import("/home/app/snaprefund-frontend/middleware/authenticated.ts"),
  "business-only": () => import("/home/app/snaprefund-frontend/middleware/business-only.ts"),
  "hide-page-for-stripe-account": () => import("/home/app/snaprefund-frontend/middleware/hide-page-for-stripe-account.ts"),
  "not-registered-only": () => import("/home/app/snaprefund-frontend/middleware/not-registered-only.ts"),
  "payments-allowed": () => import("/home/app/snaprefund-frontend/middleware/payments-allowed.ts"),
  unauthenticated: () => import("/home/app/snaprefund-frontend/middleware/unauthenticated.ts"),
  unverified: () => import("/home/app/snaprefund-frontend/middleware/unverified.ts"),
  "wallet-available": () => import("/home/app/snaprefund-frontend/middleware/wallet-available.ts"),
  auth: () => import("/home/app/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}