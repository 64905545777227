import { default as bankeSQbL0NNuuMeta } from "/home/app/snaprefund-frontend/pages/bank.vue?macro=true";
import { default as create_45accountQ0KVkzX4KbMeta } from "/home/app/base/pages/create-account.vue?macro=true";
import { default as create_45beneficialDunt33mlqsMeta } from "/home/app/base/pages/create-beneficial.vue?macro=true";
import { default as forget_45passworduhkNlHfCybMeta } from "/home/app/base/pages/forget-password.vue?macro=true";
import { default as index4GZPFaNNx0Meta } from "/home/app/snaprefund-frontend/pages/index.vue?macro=true";
import { default as loginBJExe8DX3WMeta } from "/home/app/snaprefund-frontend/pages/login.vue?macro=true";
import { default as my_45preferencedaKgW4rOOnMeta } from "/home/app/snaprefund-frontend/pages/my-preference.vue?macro=true";
import { default as _91id_93jFmKA9tgySMeta } from "/home/app/base/pages/pending-payment/[id].vue?macro=true";
import { default as indexPuFUNP1c7kMeta } from "/home/app/base/pages/pending-payment/index.vue?macro=true";
import { default as pending_45paymentp9lSRUDOqEMeta } from "/home/app/base/pages/pending-payment.vue?macro=true";
import { default as profile4AoFZr2ZFwMeta } from "/home/app/snaprefund-frontend/pages/profile.vue?macro=true";
import { default as reset_45password9ZA46tEuENMeta } from "/home/app/base/pages/reset-password.vue?macro=true";
import { default as select_45appeHptlIFxSbMeta } from "/home/app/base/pages/select-app.vue?macro=true";
import { default as indexSxaBtEMGozMeta } from "/home/app/snaprefund-frontend/pages/send-payment/index.vue?macro=true";
import { default as indexRWxCfaL4AwMeta } from "/home/app/snaprefund-frontend/pages/send-payment/mass-payment/index.vue?macro=true";
import { default as indexGMsczcbXSjMeta } from "/home/app/snaprefund-frontend/pages/send-payment/single-payment/index.vue?macro=true";
import { default as send_45paymentH9LTd5kyjXMeta } from "/home/app/snaprefund-frontend/pages/send-payment.vue?macro=true";
import { default as sign_45up_45senderhL4jcbiak0Meta } from "/home/app/snaprefund-frontend/pages/sign-up-sender.vue?macro=true";
import { default as sign_45up_45steps6Ui2hXrVlUMeta } from "/home/app/base/pages/sign-up-steps.vue?macro=true";
import { default as sign_45upiqDGYNeKtzMeta } from "/home/app/snaprefund-frontend/pages/sign-up.vue?macro=true";
import { default as SnapRefundPrivacyPolicy1NFZhYtP8SMeta } from "/home/app/base/pages/SnapRefundPrivacyPolicy.vue?macro=true";
import { default as SnapRefundToSXVQkJ4YwikMeta } from "/home/app/base/pages/SnapRefundToS.vue?macro=true";
import { default as transactionsMdK1uuXTpIMeta } from "/home/app/base/pages/transactions.vue?macro=true";
import { default as upload_45documentbESsoqES6gMeta } from "/home/app/base/pages/upload-document.vue?macro=true";
import { default as walletgdlVZMj838Meta } from "/home/app/snaprefund-frontend/pages/wallet.vue?macro=true";
export default [
  {
    name: bankeSQbL0NNuuMeta?.name ?? "bank",
    path: bankeSQbL0NNuuMeta?.path ?? "/bank",
    meta: bankeSQbL0NNuuMeta || {},
    alias: bankeSQbL0NNuuMeta?.alias || [],
    redirect: bankeSQbL0NNuuMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/bank.vue").then(m => m.default || m)
  },
  {
    name: create_45accountQ0KVkzX4KbMeta?.name ?? "create-account",
    path: create_45accountQ0KVkzX4KbMeta?.path ?? "/create-account",
    meta: create_45accountQ0KVkzX4KbMeta || {},
    alias: create_45accountQ0KVkzX4KbMeta?.alias || [],
    redirect: create_45accountQ0KVkzX4KbMeta?.redirect,
    component: () => import("/home/app/base/pages/create-account.vue").then(m => m.default || m)
  },
  {
    name: create_45beneficialDunt33mlqsMeta?.name ?? "create-beneficial",
    path: create_45beneficialDunt33mlqsMeta?.path ?? "/create-beneficial",
    meta: create_45beneficialDunt33mlqsMeta || {},
    alias: create_45beneficialDunt33mlqsMeta?.alias || [],
    redirect: create_45beneficialDunt33mlqsMeta?.redirect,
    component: () => import("/home/app/base/pages/create-beneficial.vue").then(m => m.default || m)
  },
  {
    name: forget_45passworduhkNlHfCybMeta?.name ?? "forget-password",
    path: forget_45passworduhkNlHfCybMeta?.path ?? "/forget-password",
    meta: forget_45passworduhkNlHfCybMeta || {},
    alias: forget_45passworduhkNlHfCybMeta?.alias || [],
    redirect: forget_45passworduhkNlHfCybMeta?.redirect,
    component: () => import("/home/app/base/pages/forget-password.vue").then(m => m.default || m)
  },
  {
    name: index4GZPFaNNx0Meta?.name ?? "index",
    path: index4GZPFaNNx0Meta?.path ?? "/",
    meta: index4GZPFaNNx0Meta || {},
    alias: index4GZPFaNNx0Meta?.alias || [],
    redirect: index4GZPFaNNx0Meta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginBJExe8DX3WMeta?.name ?? "login",
    path: loginBJExe8DX3WMeta?.path ?? "/login",
    meta: loginBJExe8DX3WMeta || {},
    alias: loginBJExe8DX3WMeta?.alias || [],
    redirect: loginBJExe8DX3WMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45preferencedaKgW4rOOnMeta?.name ?? "my-preference",
    path: my_45preferencedaKgW4rOOnMeta?.path ?? "/my-preference",
    meta: my_45preferencedaKgW4rOOnMeta || {},
    alias: my_45preferencedaKgW4rOOnMeta?.alias || [],
    redirect: my_45preferencedaKgW4rOOnMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/my-preference.vue").then(m => m.default || m)
  },
  {
    name: pending_45paymentp9lSRUDOqEMeta?.name ?? undefined,
    path: pending_45paymentp9lSRUDOqEMeta?.path ?? "/pending-payment",
    meta: pending_45paymentp9lSRUDOqEMeta || {},
    alias: pending_45paymentp9lSRUDOqEMeta?.alias || [],
    redirect: pending_45paymentp9lSRUDOqEMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93jFmKA9tgySMeta?.name ?? "pending-payment-id",
    path: _91id_93jFmKA9tgySMeta?.path ?? ":id()",
    meta: _91id_93jFmKA9tgySMeta || {},
    alias: _91id_93jFmKA9tgySMeta?.alias || [],
    redirect: _91id_93jFmKA9tgySMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment/[id].vue").then(m => m.default || m)
  },
  {
    name: indexPuFUNP1c7kMeta?.name ?? "pending-payment",
    path: indexPuFUNP1c7kMeta?.path ?? "",
    meta: indexPuFUNP1c7kMeta || {},
    alias: indexPuFUNP1c7kMeta?.alias || [],
    redirect: indexPuFUNP1c7kMeta?.redirect,
    component: () => import("/home/app/base/pages/pending-payment/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profile4AoFZr2ZFwMeta?.name ?? "profile",
    path: profile4AoFZr2ZFwMeta?.path ?? "/profile",
    meta: profile4AoFZr2ZFwMeta || {},
    alias: profile4AoFZr2ZFwMeta?.alias || [],
    redirect: profile4AoFZr2ZFwMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: reset_45password9ZA46tEuENMeta?.name ?? "reset-password",
    path: reset_45password9ZA46tEuENMeta?.path ?? "/reset-password",
    meta: reset_45password9ZA46tEuENMeta || {},
    alias: reset_45password9ZA46tEuENMeta?.alias || [],
    redirect: reset_45password9ZA46tEuENMeta?.redirect,
    component: () => import("/home/app/base/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: select_45appeHptlIFxSbMeta?.name ?? "select-app",
    path: select_45appeHptlIFxSbMeta?.path ?? "/select-app",
    meta: select_45appeHptlIFxSbMeta || {},
    alias: select_45appeHptlIFxSbMeta?.alias || [],
    redirect: select_45appeHptlIFxSbMeta?.redirect,
    component: () => import("/home/app/base/pages/select-app.vue").then(m => m.default || m)
  },
  {
    name: send_45paymentH9LTd5kyjXMeta?.name ?? undefined,
    path: send_45paymentH9LTd5kyjXMeta?.path ?? "/send-payment",
    meta: send_45paymentH9LTd5kyjXMeta || {},
    alias: send_45paymentH9LTd5kyjXMeta?.alias || [],
    redirect: send_45paymentH9LTd5kyjXMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/send-payment.vue").then(m => m.default || m),
    children: [
  {
    name: indexSxaBtEMGozMeta?.name ?? "send-payment",
    path: indexSxaBtEMGozMeta?.path ?? "",
    meta: indexSxaBtEMGozMeta || {},
    alias: indexSxaBtEMGozMeta?.alias || [],
    redirect: indexSxaBtEMGozMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/send-payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexRWxCfaL4AwMeta?.name ?? "send-payment-mass-payment",
    path: indexRWxCfaL4AwMeta?.path ?? "mass-payment",
    meta: indexRWxCfaL4AwMeta || {},
    alias: indexRWxCfaL4AwMeta?.alias || [],
    redirect: indexRWxCfaL4AwMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/send-payment/mass-payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexGMsczcbXSjMeta?.name ?? "send-payment-single-payment",
    path: indexGMsczcbXSjMeta?.path ?? "single-payment",
    meta: indexGMsczcbXSjMeta || {},
    alias: indexGMsczcbXSjMeta?.alias || [],
    redirect: indexGMsczcbXSjMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/send-payment/single-payment/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: sign_45up_45senderhL4jcbiak0Meta?.name ?? "sign-up-sender",
    path: sign_45up_45senderhL4jcbiak0Meta?.path ?? "/sign-up-sender",
    meta: sign_45up_45senderhL4jcbiak0Meta || {},
    alias: sign_45up_45senderhL4jcbiak0Meta?.alias || [],
    redirect: sign_45up_45senderhL4jcbiak0Meta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/sign-up-sender.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45steps6Ui2hXrVlUMeta?.name ?? "sign-up-steps",
    path: sign_45up_45steps6Ui2hXrVlUMeta?.path ?? "/sign-up-steps",
    meta: sign_45up_45steps6Ui2hXrVlUMeta || {},
    alias: sign_45up_45steps6Ui2hXrVlUMeta?.alias || [],
    redirect: sign_45up_45steps6Ui2hXrVlUMeta?.redirect,
    component: () => import("/home/app/base/pages/sign-up-steps.vue").then(m => m.default || m)
  },
  {
    name: sign_45upiqDGYNeKtzMeta?.name ?? "sign-up",
    path: sign_45upiqDGYNeKtzMeta?.path ?? "/sign-up",
    meta: sign_45upiqDGYNeKtzMeta || {},
    alias: sign_45upiqDGYNeKtzMeta?.alias || [],
    redirect: sign_45upiqDGYNeKtzMeta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.name ?? "SnapRefundPrivacyPolicy",
    path: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.path ?? "/SnapRefundPrivacyPolicy",
    meta: SnapRefundPrivacyPolicy1NFZhYtP8SMeta || {},
    alias: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.alias || [],
    redirect: SnapRefundPrivacyPolicy1NFZhYtP8SMeta?.redirect,
    component: () => import("/home/app/base/pages/SnapRefundPrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: SnapRefundToSXVQkJ4YwikMeta?.name ?? "SnapRefundToS",
    path: SnapRefundToSXVQkJ4YwikMeta?.path ?? "/SnapRefundToS",
    meta: SnapRefundToSXVQkJ4YwikMeta || {},
    alias: SnapRefundToSXVQkJ4YwikMeta?.alias || [],
    redirect: SnapRefundToSXVQkJ4YwikMeta?.redirect,
    component: () => import("/home/app/base/pages/SnapRefundToS.vue").then(m => m.default || m)
  },
  {
    name: transactionsMdK1uuXTpIMeta?.name ?? "transactions",
    path: transactionsMdK1uuXTpIMeta?.path ?? "/transactions",
    meta: transactionsMdK1uuXTpIMeta || {},
    alias: transactionsMdK1uuXTpIMeta?.alias || [],
    redirect: transactionsMdK1uuXTpIMeta?.redirect,
    component: () => import("/home/app/base/pages/transactions.vue").then(m => m.default || m)
  },
  {
    name: upload_45documentbESsoqES6gMeta?.name ?? "upload-document",
    path: upload_45documentbESsoqES6gMeta?.path ?? "/upload-document",
    meta: upload_45documentbESsoqES6gMeta || {},
    alias: upload_45documentbESsoqES6gMeta?.alias || [],
    redirect: upload_45documentbESsoqES6gMeta?.redirect,
    component: () => import("/home/app/base/pages/upload-document.vue").then(m => m.default || m)
  },
  {
    name: walletgdlVZMj838Meta?.name ?? "wallet",
    path: walletgdlVZMj838Meta?.path ?? "/wallet",
    meta: walletgdlVZMj838Meta || {},
    alias: walletgdlVZMj838Meta?.alias || [],
    redirect: walletgdlVZMj838Meta?.redirect,
    component: () => import("/home/app/snaprefund-frontend/pages/wallet.vue").then(m => m.default || m)
  }
]